import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import resolve from 'core/resolver/resolve';

import Comments from 'core/components/Comments';
import { Mobile, withBreakpoint } from 'core/components/breakpoint';

import withTheme from 'core/components/theme';
import withPageHocs from 'core/components/withPageHocs';

import { denormalizeData } from 'core/utils/api';

import Divider from 'site/components/Divider';
import TitleBackward from 'site/components/TitleBackward';
import { Indent, NegativeMobile } from 'site/components/Wrappers';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';
import modelPropTypes, { reviewAttributes } from 'site/utils/prop-types/model';

import { REVIEW_COMMENTS_ID_PREFIX } from 'site/constants';

import { CoverImage, CoverGallery } from './covers';


function ReviewCommentsPage(props) {
  const {
    theme,
    isMobile,
    review: {
      attributes: {
        name,
        slug: qutoId,
        image,
        photogallery,
      },
    },
    reviewSeo,
    match: {
      params: {
        brand,
        model,
        generation,
        body,
        review_id: reviewId,
      },
    },
  } = props;

  const imageCount = photogallery?.attributes?.image_count;

  return (
    <Fragment>
      <style jsx>{`
        .date
          color ${theme.colors.primary400}

        .title
          font-family ${theme.fonts.display}

        .cover
          background ${theme.colors.content}
          box-shadow ${isMobile ? 'none' : `0 2px 5px 0 ${theme.colors.primary100}`}
      `}</style>
      <Helmet>
        <title>{reviewSeo.title}</title>
        <meta name='description' content={reviewSeo.description} />
      </Helmet>
      <TitleBackward
        url={`/review/${brand}/${model}/${generation}/${body}/${reviewId}`}
        title={name}
      />
      <Indent bottom={15} />
      <NegativeMobile>
        {image && imageCount === 1 && <CoverImage image={image} />}
        {!!imageCount && imageCount > 1 && <CoverGallery photoGallery={photogallery} />}
      </NegativeMobile>
      <Mobile>
        <Divider top={26} bottom={20} />
      </Mobile>
      <Comments xid={REVIEW_COMMENTS_ID_PREFIX + qutoId} />
    </Fragment>
  );
}

ReviewCommentsPage.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      generation: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      review_id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  review: modelPropTypes(reviewAttributes),
  reviewSeo: PropTypes.object,
  theme: PropTypes.object,
};

const dataProvider = resolve({
  review: props => {
    const {
      servicesApi,
      renderError,
      match: {
        params: {
          brand,
          model,
          generation,
          body,
          review_id: reviewId,
        },
      },
    } = props;

    return servicesApi
      .getReviews({
        'filter[brand]': brand,
        'filter[model]': model,
        'filter[body]': body,
        'filter[generation]': generation,
        'filter[review]': reviewId,
        'attributes[review]': 'base,image,media',
        limit: 1,
      })
      .then(reviewsData => denormalizeData(reviewsData)[0])
      .then(rejectEmptyResult)
      .catch(renderError);
  },

  reviewSeo: ({ servicesApi, reviewSeo, match }) => {
    if (reviewSeo) return reviewSeo;

    return servicesApi.getReviewsSeo(match)
      .then(response => response.data)
      .catch(() => ({}));
  },
});

export default withPageHocs(dataProvider)(withBreakpoint(withTheme(ReviewCommentsPage)));
