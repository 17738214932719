import { Fragment } from 'react';

import { Helmet } from 'core/libs/helmet';

import CoreRulesRecommenders from 'core/components/RulesRecommenders';


const title = 'Правила применения рекомендательных технологий';
const description = 'Правила применения рекомендательных технологий сайта quto.ru';

export default function RulesRecommenders() {
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
      </Helmet>
      <CoreRulesRecommenders mail='info@quto.ru' />
    </Fragment>
  );
}
