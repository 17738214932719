import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { getOffset } from 'core/utils/url-helper';
import { denormalizeData } from 'core/utils/api';

import withPageHocs from 'core/components/withPageHocs';
import { withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';

import H1 from 'core/components/H1';
import GridFeed from 'core/components/GridFeed';
import ListPage from 'core/components/ListPage';

import PageLayoutService from 'site/components/PageLayoutService';
import { Indent } from 'site/components/Wrappers';

import CardServiceEditorChoice from 'site/cardsService/CardServiceEditorChoice';

import { getTypeOfPage } from 'site/utils';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

const limit = 15;
const CardServiceEditorChoiceMobile = bindProps({ imageMaxWidth: 400 })(CardServiceEditorChoice);


function BestChoicePage(props) {
  const {
    rawContent,
    editorsChoiceSeo: {
      title,
      description,
      h1,
    },
    isMobile,
    isDesktop,
  } = props;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <PageLayoutService>
      <ListPage
        rawData={rawContent}
        limit={limit}
        title={title}
        description={description}
      >
        {({ content }) => (
          <Indent bottom={vertical}>
            {<H1>{h1 || title}</H1>}
            {!!description && <p>{description}</p>}
            <Indent bottom={vertical} />
            <GridFeed
              content={content}
              card={isDesktop ? CardServiceEditorChoice : CardServiceEditorChoiceMobile}
              gap='20px'
              {...isDesktop && { gridTemplateColumns: '1fr 1fr 1fr' }}
            />
          </Indent>
        )}
      </ListPage>
    </PageLayoutService>
  );
}

BestChoicePage.propTypes = {
  rawContent: PropTypes.object,
  editorsChoiceSeo: PropTypes.object,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
};

const dataProvider = resolve({
  rawContent: props => {
    const {
      servicesApi,
      renderError,
      location: {
        search,
        pathname,
      },
    } = props;

    return servicesApi
      .getEditorsChoice({
        'attributes[car_editors_choice]': 'base,image',
        'filter[type]': getTypeOfPage(pathname),
        limit,
        offset: getOffset(search, limit),
        with_filtered_count: true,
      })
      .catch(renderError);
  },
  editorsChoiceSeo: props => {
    const {
      servicesApi,
      consoleError,
      location: {
        pathname,
      },
    } = props;

    return servicesApi
      .getEditorsChoiceSeo({
        'filter[bestchoice]': getTypeOfPage(pathname) + '_main_meta',
      })
      .then(denormalizeData)
      .catch(consoleError('bestchoice meta', {}));
  },
});

export default withPageHocs(dataProvider)(withBreakpoint(BestChoicePage));
