import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import RecommendationWidget from 'core/components/RecommendationWidget';


function SiteRecommender({ isMobile }) {
  return (
    <RecommendationWidget
      blockId={isMobile ? '0c7458fbe01942779a1488109b1752aa' : 'fe3d68f9eb2040cb9fc379379a9d99cd'}
      title='Популярное'
    />
  );
}

SiteRecommender.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
