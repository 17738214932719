import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { denormalizeData } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';

import { withRouter } from 'core/libs/router';

import { withBreakpoint } from 'core/components/breakpoint';

import H3 from 'core/components/H3';
import { SimpleLoader } from 'core/components/Loader';

import SmartDrum from 'site/components/SmartDrum';
import { Indent } from 'site/components/Wrappers';
import Divider from 'site/components/Divider';
import withGeo from 'site/components/GeoContext/withGeo';

import { isMotoPath } from 'site/utils';

import CardServiceOffer1 from 'site/cardsService/CardServiceOffer1';

import modelPropTypes, {
  offerAttributes,
} from 'site/utils/prop-types/model';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


const relationships = resolveRelationships(['release', 'modification', 'body', 'class', 'geo', 'dealer'], {}, {});

const filterParams = [
  'is_new',
  'is_trade_in',
];

function SimilarOffers(props, { servicesApi }) {
  const {
    isMobile,
    offer,
    getGeoAttributes,
    geoSlug,
    location: {
      pathname,
    },
    match: {
      params,
    },
  } = props;

  const [content, setContent] = useState({
    similarNew: [],
    similarUsed: [],
  });
  const [loading, setLoading] = useState(true);

  const {
    brand,
    model,
    generation,
  } = params;

  useEffect(() => {
    let ignore = false;

    const exitFunc = () => {
      ignore = true;
    };

    if (!geoSlug) return exitFunc;

    const isMoto = isMotoPath(pathname);
    const vehiclePrefix = isMoto ? 'moto' : 'car';

    const isOfferPage = pathname.indexOf('/inventory/') !== -1;

    let commonApiParams = {
      'include': `${vehiclePrefix}_brand,${vehiclePrefix}_model,car_modification,dealer,dealer_network`,
      'filter[geo]': geoSlug,
      'limit': 9,
      'attributes[dealer_network]': 'base,image',
      [`attributes[dealer_offer${isMoto ? '_moto' : ''}]`]: 'base,image',
      [`relations[dealer_offer${isMoto ? '_moto' : ''}]`]: 'brand,model,modification,dealer,dealer_network',
    };

    if (isOfferPage) {
      const { price, slug } = offer?.attributes || {};

      const relations = relationships(offer);

      commonApiParams = {
        ...commonApiParams, ...{
          'filter[price_max]': Math.floor(price * 1.1),
          'filter[price_min]': Math.floor(price * 0.9),
          'filter[class]': relations.class.url,
          'filter[offer]': `!${slug}`,
          'relations[dealer]': 'dealer_network',
        },
      };
    } else {
      commonApiParams = {
        ...commonApiParams, ...{
          'filter[brand]': brand,
          'filter[model]': model,
          'filter[generation]': generation,
          'attributes[dealer_network]': 'image',
        },
      };
    }

    const apiMethod = isMoto ? 'getMotoOffers' : 'getOffers';
    const payloads = filterParams.map(filterParam => {
      return servicesApi[apiMethod]({
        ...commonApiParams,
        [`filter[${filterParam}]`]: true,
      })
        .then(denormalizeData)
        .catch(e => {
          console.error(e);
          return [];
        });
    });

    Promise.allSettled(payloads)
      .then(([similarNew, similarUsed]) => ({
        similarNew: similarNew.value || [],
        similarUsed: similarUsed.value || [],
      }))
      .then(data => {
        if (!ignore) {
          setContent(data);
          setLoading(false);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setLoading(false);
        }
      });

    return exitFunc;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand, generation, model, geoSlug, pathname]);

  if (loading) return <SimpleLoader />;

  const {
    similarNew,
    similarUsed,
  } = content;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const geoNameGenitive = geoSlug ? getGeoAttributes(geoSlug).name_genitive : '';

  const isOfferPage = pathname.indexOf('/inventory/') !== -1;
  const isMoto = isMotoPath(pathname);

  const typeTransportTitle = isMoto ? 'мотоциклы' : 'автомобили';

  const titleNewOffers = isOfferPage ? `Новые ${typeTransportTitle} за те же деньги` :
    `Новые ${typeTransportTitle} в автосалонах ${geoNameGenitive}`;

  const titleUsedOffers = isOfferPage ? 'Похожие предложения с пробегом' :
    `${typeTransportTitle[0].toUpperCase() + typeTransportTitle.slice(1)} с пробегом в автосалонах ${geoNameGenitive}`;


  const commonProps = {
    headerComponent: H3,
    itemWidth: '250px',
    itemWidthMobile: '250px',
    card: CardServiceOffer1,
    showHeaderArrows: true,
    showArrowsOnHover: true,
    arrowsShift: -10,
  };

  return (
    <Fragment>
      {similarUsed.length > 0 && (
        <Indent bottom={vertical}>
          <Divider top={30} bottom={20} />
          <SmartDrum
            {...commonProps}
            title={titleUsedOffers}
            content={similarUsed}
          />
        </Indent>
      )}

      {similarNew.length > 0 && (
        <Indent bottom={vertical}>
          <Divider top={30} bottom={20} />
          <SmartDrum
            {...commonProps}
            title={titleNewOffers}
            content={similarNew}
          />
        </Indent>
      )}
    </Fragment>
  );
}

SimilarOffers.propTypes = {
  isMobile: PropTypes.bool,
  offer: modelPropTypes(offerAttributes),
  geoSlug: PropTypes.string,
  getGeoAttributes: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};

SimilarOffers.contextTypes = {
  servicesApi: PropTypes.object.isRequired,
};

export default withGeo(withRouter(withBreakpoint(SimilarOffers)));
