import resolve from 'core/resolver/resolve';

import { denormalizeData } from 'core/utils/api';
import { getOffset } from 'core/utils/url-helper';

import { compose } from 'core/libs/recompose';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import comparisonsFetcher from 'site/fetchers/comparisons';

import { BEST_CHOICE_LIMIT } from 'site/constants';


const bestChoiceProvider = resolve({
  bestChoice(props) {
    const {
      servicesApi,
      renderError,
      match: {
        params: {
          lcv,
          moto,
          slug,
        },
      },
    } = props;

    const type = lcv || moto || 'cars';

    return servicesApi.getEditorsChoice({
      'attributes[car_editors_choice]': 'base,text,seo',
      'filter[type]': type,
      'filter[slug]': slug,
    })
      .then(result => result.data[0])
      .then(rejectEmptyResult)
      .catch(renderError);
  },
});

const dataProvider = resolve({
  editorsChoice: ({ servicesApi, consoleError, bestChoice }) => {
    const vehicleType = bestChoice?.attributes?.type || 'cars';

    return servicesApi
      .getEditorsChoice({
        'filter[type]': vehicleType,
        'attributes[car_editors_choice]': 'base,image,seo',
        limit: 6,
      })
      .then(denormalizeData)
      .catch(consoleError('editorsChoice', []));
  },

  editorsChoiceSeo: props => {
    const {
      servicesApi,
      consoleError,
      match,
    } = props;

    return servicesApi
      .getEditorsChoiceSeo({
        'filter[bestchoice]': match.params.slug,
      })
      .then(denormalizeData)
      .catch(consoleError('bestchoice selection meta', {}));
  },

  comparisons: comparisonsFetcher(),

  selections(props) {
    const {
      servicesApi,
      consoleError,
      match: {
        params: {
          slug,
          moto,
        },
      },
      location: {
        search,
      },
    } = props;

    const type = moto || 'car';
    const method = type === 'car' ? servicesApi.getReleases : servicesApi.getMotoReleases;

    return method({
      'filter[editors_choice]': slug,
      [`attributes[${type}_release]`]: 'base,image,stats',
      with_filtered_count: true,
      limit: BEST_CHOICE_LIMIT,
      offset: getOffset(search, BEST_CHOICE_LIMIT),
    })
      .catch(consoleError('selections', {}));
  },
});

export default compose(bestChoiceProvider, dataProvider);
