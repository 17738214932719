import { useCallback, useState, useEffect, useRef, Fragment, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import skip from 'core/resolver/skip';
import resolve from 'core/resolver/resolve';

import { SimpleLoader } from 'core/components/Loader';

import { denormalizeData } from 'core/utils/api';
import { SessionStorage } from 'core/decorators';

import {
  getRandomTicket,
  checkExamResult,
} from 'site/utils/pdd';

import PddHeading from 'site/components/PddHeading';
import QuestionsNavigation from 'site/components/QuestionsNavigation';
import { Indent } from 'site/components/Wrappers';

import Questions from './Questions';

import reducer, { init } from './reducer';

import { PDD_EXAM_MAX_MISTAKES, PDD_TICKET_QUESTIONS_COUNT } from 'site/constants';

const EXAM_TIME = 20 * 60;
const ADD_TIME = 5 * 60;


function PddExam(props, { servicesApi }) {
  const {
    mode,
    router: {
      route: {
        match: {
          params,
        },
      },
    },
  } = props;

  const [state, dispatch] = useReducer(reducer, { ticketNumber: params.ticketNumber || getRandomTicket() }, init);
  const [seconds, setSeconds] = useState(EXAM_TIME);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const interval = useRef(null);

  const {
    answers,
    mistakes,
    additionalQuestions,
    ticketNumber,
    questionIndex,
  } = state;

  const isTren = mode === 'tren';

  let processHeading = 'Экзамен';
  let resultHeading = 'онлайн-экзамена';
  let storageName = 'examState';

  if (isTren) {
    processHeading = `Тренировка. Билет\u00A0№\u00A0${ticketNumber}`;
    resultHeading = 'тренировки';
    storageName = 'trenState';
  }

  const examResult = useMemo(() => {
    return checkExamResult(answers, mistakes, seconds, mode);
  }, [answers, mistakes, seconds, mode]);

  const isFinish = !!examResult;

  // Устанавливаем состояние из стораджа
  useEffect(() => {
    const examState = JSON.parse(SessionStorage.getItem(storageName));

    if (!examState) return;

    const ticketNumberFromParams = params.ticketNumber;

    if (ticketNumberFromParams) {
      return;
    }

    dispatch({ type: 'reset', payload: examState });

    // с учетом допполнительного времени
    const secondsToExam = EXAM_TIME + ADD_TIME * Math.min(examState.mistakes.length, PDD_EXAM_MAX_MISTAKES);
    const finishTime = examState.finishTime || Date.now();
    const secondsPassed = (finishTime - examState.startTime) / 1000;

    let secondsLeft = secondsToExam - secondsPassed;
    if (secondsLeft < 0) {
      secondsLeft = 0;
    }
    setSeconds(secondsLeft);
  }, [params.ticketNumber, storageName]);

  useEffect(() => {
    let ignore = false;

    const exitEffect = () => {
      ignore = true;
    };

    if (!ticketNumber) {
      setLoading(false);
      return exitEffect;
    }

    // Получаем вопросы для номера билета
    servicesApi.getPddQuestions({
      'filter[ticket]': ticketNumber,
      'attributes[pdd_question]': 'base,image',
      'relations[pdd_question]': 'category',
      'include': 'pdd_category',
      'limit': PDD_TICKET_QUESTIONS_COUNT,
    })
      .then(denormalizeData)
      .then(data => {
        if (!ignore) {
          setQuestions(data);
          setLoading(false);
        }
      })
      .catch(e => {
        if (!ignore) {
          console.error(e);
          setLoading(false);
        }
      });

    return exitEffect;
  }, [ticketNumber, storageName, servicesApi]);

  // Эффект подгружает дополнительные вопросы при ошибках
  useEffect(() => {
    const limit = 5;

    if (mistakes.length <= additionalQuestions.length / limit
      || mistakes.length > PDD_EXAM_MAX_MISTAKES
      || isFinish
    ) {
      return;
    }

    servicesApi.getPddQuestions({
      'attributes[pdd_question]': 'base,image',
      'limit': limit,
      'filter[category]': mistakes[mistakes.length - 1].category,
      'filter[except_ticket]': ticketNumber,
    })
      .then(denormalizeData)
      .then(data => {
        dispatch({ type: 'setAdditionalQuestions', payload: data });
        setSeconds(sec => sec + ADD_TIME);
      })
      .catch(e => console.error(e));
  }, [isFinish, additionalQuestions.length, mistakes, ticketNumber, servicesApi]);

  // Эффект запускает таймер
  useEffect(() => {
    if (!interval.current) {
      interval.current = setInterval(() => {
        setSeconds(sec => sec - 1);
      }, 1000);
    }
    if (isFinish) {
      dispatch({ type: 'setFinishTime', payload: Date.now() });
      clearInterval(interval.current);
      interval.current = null;
    }
    return () => {
      clearInterval(interval.current);
    };
  }, [isFinish]);

  // Эффект сохраняет данные в sessionStorage
  useEffect(() => {
    !params.ticketNumber && SessionStorage.setItem(storageName, JSON.stringify(state));
  }, [state, storageName, params.ticketNumber]);

  const resetTicket = useCallback(() => {
    dispatch({ type: 'reset' });
    setSeconds(EXAM_TIME);
  }, []);

  const goToNextQuestion = useCallback(() => {
    dispatch({ type: 'next' });
  }, []);

  const goToPrevQuestion = useCallback(() => {
    dispatch({ type: 'prev' });
  }, []);

  const handleAnswer = useCallback((question, answer) => {
    dispatch({ type: 'saveAnswer', payload: { question, answer } });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{`Билет № ${ticketNumber} - ${isTren ? 'тренировка -' : ''} Экзамен ПДД онлайн`}</title>
      </Helmet>
      <PddHeading
        finish={isFinish}
        time={seconds}
        mode={mode}
      >
        {isFinish
          ? `Результаты ${resultHeading} ПДД`
          : processHeading
        }
      </PddHeading>
      <Indent bottom={22} />
      <QuestionsNavigation
        active={questionIndex + 1}
        mode={mode}
        answers={answers}
        finish={isFinish}
      />
      <Indent bottom={32} />
      {ticketNumber && (
        loading
          ? <SimpleLoader />
          : (
            <Questions
              questions={questions}
              isFinish={isFinish}
              examResult={examResult}
              goToPrevQuestion={goToPrevQuestion}
              goToNextQuestion={goToNextQuestion}
              resetTicket={resetTicket}
              handleAnswer={handleAnswer}
              additionalQuestions={additionalQuestions}
              answers={answers}
              questionIndex={questionIndex}
              ticketNumber={ticketNumber}
              key={ticketNumber}
              mode={mode}
            />
          )
      )}
    </Fragment>
  );
}

PddExam.defaultProps = {
  mode: 'exam',
};

PddExam.propTypes = {
  ticketNumber: PropTypes.number.isRequired,
  mode: PropTypes.oneOf(['tren', 'exam']),
  router: PropTypes.object.isRequired,
};

PddExam.contextTypes = {
  servicesApi: PropTypes.object.isRequired,
};

export default skip(resolve('ticketNumber', ({ router }) => {
  return router.route.match.params.ticketNumber || Promise.resolve(getRandomTicket());
})(PddExam));
