import { useEffect, Fragment, useState,  useCallback } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';
import { compose } from 'core/libs/recompose';

import skip from 'core/resolver/skip';

import { LocalStorage } from 'core/decorators';

import H5 from 'core/components/H5';
import SimpleLoader from 'core/components/Loader';

import PageLayout from 'site/components/PageLayout';
import { Indent } from 'site/components/Wrappers';
import PddHeading from 'site/components/PddHeading/';
import QuestionsNavigation from 'site/components/QuestionsNavigation/';
import PddQuestion from 'site/components/PddQuestion/index.js';
import PddResult from 'site/components/PddResult';

import Blocks from 'site/pages/pdd/components/Blocks';

import { isGetAnswer, getPrevQuestionIndex, getNextQuestionIndex } from 'site/utils/pdd';

import { PDD_ALL_QUESTIONS_COUNT, PDD_TICKET_QUESTIONS_COUNT, ERROR_MESSAGE } from 'site/constants';


const title = 'Тест-марафон на сдачу экзамена в ГИБДД: билеты, подсказки';
const description = 'Все 800 вопросов из билетов на сдачу экзамена по вождению в ГИБДД, неограниченное время и количество ошибок, подсказки для тренировки. Онлайн-тест на знание правил ПДД на Quto.ru';

function Marafon({ servicesApi }) {
  const [countQuestion, setCountQuestion] = useState(0);

  const initialAnswers = Array(PDD_ALL_QUESTIONS_COUNT).fill(null);

  const [userAnswers, setUserAnswers] = useState(initialAnswers);

  const [questions, setQuestions] =  useState([]);

  const numberTicket = Math.trunc(countQuestion / PDD_TICKET_QUESTIONS_COUNT) + 1;

  const finish = userAnswers.every((answer) => isGetAnswer(answer));

  const activeQuestion = countQuestion - (numberTicket - 1) * PDD_TICKET_QUESTIONS_COUNT;

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const countQuestionStorage = JSON.parse(LocalStorage.getItem('countQuestion'));
    const userAnswersStorage = JSON.parse(LocalStorage.getItem('userAnswers'));

    setCountQuestion(countQuestionStorage || 0);
    setUserAnswers(userAnswersStorage || initialAnswers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let unmounted = false;
    servicesApi
      .getPddQuestions({
        'filter[ticket]': numberTicket,
        'attributes[pdd_question]': 'base,image',
      })
      .then((response) => {
        if (!unmounted) setQuestions(response.data);
      })
      .catch(error => {
        console.error(error);
        setIsError(true);
      });

    return () => {unmounted = true;};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberTicket]);

  useEffect(() => {
    LocalStorage.setItem('userAnswers', JSON.stringify(userAnswers));
  }, [userAnswers]);

  useEffect(() => {
    LocalStorage.setItem('countQuestion', JSON.stringify(countQuestion));
  }, [countQuestion]);

  const handleNextQuestion = useCallback(() => {
    const questionIndex = getNextQuestionIndex(userAnswers, countQuestion);
    if (questionIndex > -1) {
      setCountQuestion(questionIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countQuestion]);

  const handlePrevQuestion = useCallback(() => {
    const questionIndex = getPrevQuestionIndex(userAnswers, countQuestion);
    if (questionIndex > -1) {
      setCountQuestion(questionIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countQuestion]);

  const saveAnswer = useCallback((answer) => {
    const correctAnswer = questions
      && questions[activeQuestion]
      && questions[activeQuestion].attributes.correct_answer;

    const objAnswer = {
      answer,
      isCorrect: answer ===  correctAnswer,
    };
    setUserAnswers((prev) => {
      const newState = [...prev];
      newState[countQuestion] = objAnswer;
      return newState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countQuestion, questions]);

  const handleReset = useCallback(() => {
    LocalStorage.removeItem('userAnswers');
    LocalStorage.removeItem('countQuestion');

    setCountQuestion(0);
    setUserAnswers(initialAnswers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finish]);

  return (
    <PageLayout>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
      </Helmet>
      <Indent bottom={20} />
      <PddHeading
        finish={finish}
        mode='marafon'
      >
        {finish ? 'Результаты Марафона' : 'Марафон'}
      </PddHeading>
      <Indent bottom={20} />
      <QuestionsNavigation
        active={countQuestion + 1}
        mode='marafon'
        answers={userAnswers}
        finish={finish}
      />
      <Indent bottom={20} />
      {isError && <H5>{ERROR_MESSAGE}</H5>}
      {questions.length === 0 && !isError && <SimpleLoader />}
      {!finish &&  questions.length > 0 &&
        <PddQuestion
          question={questions[activeQuestion]}
          mode='marafon'
          handlePrevQuestion={handlePrevQuestion}
          handleNextQuestion={handleNextQuestion}
          saveAnswer={saveAnswer}
          userAnswer={isGetAnswer(userAnswers[countQuestion]) ? userAnswers[countQuestion].answer : null}
        />
      }
      { finish &&
        <Fragment>
          <PddResult
            questions={questions}
            answers={userAnswers}
            handleReset= {handleReset}
            mode='marafon'
          />
          <Indent bottom={50} />
          <Blocks mode = 'marafon' finish={finish} />
        </Fragment>
      }
      <Indent bottom={50} />
    </PageLayout>
  );
}

Marafon.propTypes = {
  servicesApi: PropTypes.object,
};

export default compose(
  skip,
)(Marafon);
