import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';
import { Helmet } from 'core/libs/helmet';

import resolveRelationships from 'core/utils/relationships';

import resolve from 'core/resolver/resolve';
import { denormalizeData } from 'core/utils/api';

import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import Specifications from 'site/components/Specifications';
import CustomLink from 'site/components/CustomLink';
import CostList from 'site/components/CostList';
import TitleBackward from 'site/components/TitleBackward';

import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model';
import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { Indent } from 'site/components/Wrappers';

import comparisonsFetcher from 'site/fetchers/comparisons';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import { processLcvUrlPrefix } from 'site/utils';

import Options from './options';
import Properties from './properties';
import ModificationLayout from './layout';

import ConfiguratorProvider from 'site/components/ConfiguratorProvider';

const requiredPayloadImports = ['release'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  attributes: {},
});

function ModificationPage(props) {
  const {
    isMobile,
    modification,
    modificationSeo,
    comparisons,
    match: {
      params: {
        lcv: lcvSlug,
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
        body: bodySlug,
        modification: modificationSlug,
        property: propertySlug,
      },
    },
  } = props;

  const {
    release: {
      name,
    },
  } = relationships(modification);

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const isPropertyPage = !!propertySlug;
  const lcvUrlPrefix = processLcvUrlPrefix(Boolean(lcvSlug));
  const pageBaseUrl = `${lcvUrlPrefix}/${brandSlug}/${modelSlug}/${generationSlug}/${bodySlug}/${modificationSlug}/`;
  const backUrl = `${lcvUrlPrefix}/${brandSlug}/${modelSlug}/${generationSlug}/${bodySlug}`;

  const {
    have_options: haveOptions,
  } = modification.attributes;

  return (
    <Fragment>
      <Helmet>
        <title>{modificationSeo.title}</title>
        <meta name='description' content={modificationSeo.description} />
      </Helmet>
      <ConfiguratorProvider modifications={[modification]}>
        <ModificationLayout comparisons={comparisons} modification={modification}>
          <Mobile>
            <TitleBackward title={name} url={backUrl} />
            <Indent bottom={vertical} />
          </Mobile>
          <CostList modification={modification} />
          <Indent bottom={VERTICAL_INDENT} />
          <Specifications modification={modification} />
          <Indent bottom={VERTICAL_INDENT} />
          {haveOptions && (
            <Indent bottom={VERTICAL_INDENT}>
              {isPropertyPage
                ? (
                  <CustomLink to={pageBaseUrl}>
                    Показать комплектации и опции
                  </CustomLink>
                ) : (
                  <CustomLink to={`${pageBaseUrl}property`}>
                    Показать все тех. характеристики
                  </CustomLink>
                )
              }
            </Indent>
          )}
          {isPropertyPage
            ? <Properties modification={modification} />
            : <Options modification={modification} />
          }
        </ModificationLayout>
      </ConfiguratorProvider>
    </Fragment>
  );
}

const modificationProvider = resolve({
  modification: props => {
    const {
      servicesApi,
      renderError,
      match,
    } = props;

    const {
      brand: brandSlug,
      model: modelSlug,
      generation: generationSlug,
      body: bodySlug,
      modification: modificationSlug,
      property: isPropertyPage,
    } = match.params;

    return (
      servicesApi.getModifications({
        'filter[brand]': brandSlug,
        'filter[model]': modelSlug,
        'filter[generation]': generationSlug,
        'filter[body]': bodySlug,
        'filter[modification]': modificationSlug,
        'attributes[car_modification]': `base,extended,ownership,stats,options,${isPropertyPage ? 'specifications' : ''}`,
        'relations[car_modification]': 'release,brand',
        include: 'car_release,car_brand',
        'attributes[car_release]': 'base,image,stats',
        limit: 1,
      })
        .then(modificationsData => denormalizeData(modificationsData)[0])
        .then(rejectEmptyResult)
        .catch(renderError)
    );
  },
});

const dataProvider = resolve({
  modificationSeo: props => {
    const {
      servicesApi,
      consoleError,
      match,
    } = props;

    const {
      brand: brandSlug,
      model: modelSlug,
      generation: generationSlug,
      body: bodySlug,
      modification: modificationSlug,
      property: isPropertyPage,
    } = match.params;

    const seoAPI = isPropertyPage ? servicesApi.getModificationPropertiesSeo : servicesApi.getModificationSeo;

    return (
      seoAPI({
        'filter[brand]': brandSlug,
        'filter[model]': modelSlug,
        'filter[generation]': generationSlug,
        'filter[submodel]': bodySlug,
        'filter[modification]': modificationSlug,
      })
        .then(denormalizeData)
        .catch(consoleError('modificationSeo', {}))
    );
  },

  comparisons: ({ modification, ...otherProps }) => comparisonsFetcher({ id: modification?.id })(otherProps),
});

const composedProvider = compose(modificationProvider, dataProvider);

ModificationPage.propTypes = {
  isMobile: PropTypes.bool,
  modification: modelPropTypes(carModificationAttributes),
  modificationSeo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  comparisons: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
      modification: PropTypes.string,
      property: PropTypes.string,
    }),
  }).isRequired,
};

export default withPageHocs(composedProvider)(withBreakpoint(ModificationPage));
